'use client'

import Link from 'next/link'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/24/solid'
import Symbol from '@hiretalk/components/elements/symbol'
import type { AwaitedReturnData } from '@hiretalk/types/awaited-return'
import type getUser from '@hiretalk/libraries/get-user'

type NAV = {
  href: string
  label: string
  cta?: boolean
}

type Props = {
  user: AwaitedReturnData<typeof getUser>
}

const NavbarMenus = ({ user }: Props) => {
  const NAVLINKS: NAV[] = [
    { href: '/#features', label: 'Features' },
    { href: '/about', label: 'About' },
    { href: '/#how-it-works', label: 'How It Works' },
    { href: user ? '/companies' : '/login', label: user ? 'Dashboard' : 'Login' },
    { href: '/login', label: 'Get Started - Free', cta: true },
  ]
  return (
    <>
      {/* laptop */}
      <div className='hidden lg:block'>
        <ul className='flex justify-between space-x-6 text-lg'>
          {NAVLINKS.map((menu) => {
            if (menu.cta) {
              return (
                <li key={menu.label}>
                  <Link
                    href={menu.href}
                    className=' bg-leaf px-4 py-2 text-base font-semibold text-white sm:px-4 sm:py-2 sm:text-lg'>
                    {menu.label}
                  </Link>
                </li>
              )
            }
            return (
              <li key={menu.label}>
                <Link href={menu.href} className='text-black hover:text-leaf'>
                  {menu.label}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>

      {/* mobile */}
      <div className='lg:hidden'>
        <Menu>
          <MenuButton>
            <Symbol Icon={Bars3Icon} theme='green' size='medium' />
          </MenuButton>
          <MenuItems anchor='bottom' className='flex w-64 flex-col rounded bg-white shadow-md'>
            {NAVLINKS.map((menu) => {
              if (menu.cta) {
                return (
                  <MenuItem key={menu.label}>
                    <Link
                      href={menu.href}
                      className=' bg-leaf px-4 py-2 text-base font-semibold text-white sm:px-4 sm:py-2 sm:text-lg'>
                      {menu.label}
                    </Link>
                  </MenuItem>
                )
              }
              return (
                <MenuItem key={menu.label}>
                  <Link href={menu.href} className='px-4 py-2 text-black hover:bg-leaf hover:text-white'>
                    {menu.label}
                  </Link>
                </MenuItem>
              )
            })}
          </MenuItems>
        </Menu>
      </div>
    </>
  )
}

export default NavbarMenus
