import Tooltip from '../tooltip'
import style, { type Style } from './style'

type Props = Style & {
  Icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
      title?: string | undefined
      titleId?: string | undefined
    } & React.RefAttributes<SVGSVGElement>
  >
  className?: string
  onClick?: () => void
  tooltip?: string
}

const Symbol = ({ Icon, className = '', onClick = () => {}, tooltip, ...rest }: Props) => {
  if (tooltip)
    return (
      <Tooltip content={tooltip}>
        <Icon onClick={onClick} className={`${style(rest)} ${className}`} />
      </Tooltip>
    )

  return <Icon onClick={onClick} className={`${style(rest)} ${className}`} />
}

export default Symbol
