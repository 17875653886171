import { cva, type VariantProps } from 'class-variance-authority'

const style = cva('cursor-pointer', {
  variants: {
    size: {
      extraSmall: 'w-4 h-4',
      small: 'w-5 h-5',
      medium: 'w-7 h-7',
      large: 'w-9 h-9',
    },
    theme: {
      none: 'text-[#6C6C6C]',
      white: 'text-white',
      violet: 'text-primary-purple-light',
      yellow: 'text-primary-yellow',
      orange: 'text-secondary-orange',
      green: 'text-secondary-green',
      red: 'text-secondary-red',
      gray: 'text-secondary-gray-dark',
    },
  },
  defaultVariants: {
    size: 'small',
    theme: 'white',
  },
})

export default style

export type Style = VariantProps<typeof style>
