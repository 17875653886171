import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"app/(marketing)/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"weight\":\"400\",\"style\":\"normal\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"app/(marketing)/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"weight\":\"700\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"montserratBold\"}");
;
import(/* webpackMode: "eager" */ "/workspace/shared/dist/hooks/use-voice-recorder.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/web/app/(marketing)/_components/navbar/menus.tsx");
