'use client'

import type { ReactNode } from 'react'
import Symbol from '../symbol'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'

interface TooltipProps {
  children?: ReactNode
  content: string | ReactNode
  hasIcon?: boolean
}

const Tooltip: React.FC<TooltipProps> = ({ children, content, hasIcon }) => {
  return (
    <div className='group relative inline-block'>
      {hasIcon ? (
        <Symbol Icon={QuestionMarkCircleIcon} size='small' />
      ) : (
        <span className='cursor-help'>{children}</span>
      )}
      <div className='invisible absolute bottom-full left-1/2 z-10 mb-1 w-max max-w-xs -translate-x-1/2 rounded-md bg-gray-800 px-2 py-1 text-sm text-white opacity-0 transition group-hover:visible group-hover:opacity-100'>
        {content}
        <svg className='absolute left-0 top-full h-2 w-full text-gray-800' x='0px' y='0px' viewBox='0 0 255 255'>
          <polygon className='fill-current' points='0,0 127.5,127.5 255,0' />
        </svg>
      </div>
    </div>
  )
}

export default Tooltip
